<template>
    <setting-layout :title="$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.title')">
        <v-container>
            <v-form>
                <v-row v-for="(item, i) in fields" :key="i">
                    <v-col>
                        <div class="pb-1" :class="{'error--text': errors[getFieldName(item.label)]}">{{ item.label }}</div>
                        <text-field v-if='item.type==="text"'
                                    :placeholder="item.placeholder"
                                    :error-messages="errors[getFieldName(item.label)]"
                                    v-model="model[getFieldName(item.label)]"/>
                        <v-select
                            v-else
                            background-color="rgba(237,237,237, .1)"
                            color="orange"
                            :items="item.items"

                            :placeholder="item.placeholder"
                            solo
                            flat
                            item-color="orange"
                            item-text="text"
                            item-value="val"
                            v-model="model[getFieldName(item.label)]"
                            hide-details


                        ></v-select>
                    </v-col>
                </v-row>
                <p class="text-body-2 mt-3">
                    {{$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.note_1')}}
                </p>
                <p class="text-body-2">
                    {{$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.note_2')}}
                </p>
                <bottom-control-block :checked="defaultBankTransfer"
                                      days-receive="7-10"
                                      max-days-receive="10"
                                      min-payment="100"
                                      @change="changeDefault"
                                      :loading="$store.getters.loadingBtn(['setting', 'withdraw', 'directBtn'])"
                                      @done="save">
                </bottom-control-block>

            </v-form>

        </v-container>

        <WithdrawalFormErrorDialog
            v-if="this.isShowWithdrawFormErrorDialog"
            @close="handleCloseWithdrawErrorFormDialog"
        >
            <template #title>
                {{ $t('dialogs.withdrawal_form_error.bank_transfer.title') }}
            </template>

            <template #description>
                {{ $t('dialogs.withdrawal_form_error.bank_transfer.description') }}
            </template>
        </WithdrawalFormErrorDialog>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import countriesSelect from '@/configs/countriesSelect'
import BottomControlBlock from "@/components/creator/BottomControlBlock";
import {mapActions} from "vuex";
import AccessCameraDialog from "@/components/app/dialogs/AccessCameraDialog.vue";
import WithdrawalFormErrorDialog from "@/components/app/dialogs/WithdrawalFormError.vue";

export default {
    name: "BankTransfer",
    data() {
        return {
            success: {},
            errors: {},
            model: {},
            defaultBankTransfer: this.$store.state.user.user.data.withdrawDefault === 'bank',
            isShowWithdrawFormErrorDialog: false,
        }
    },
    computed: {
        fields() {
            return [
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_swift_code'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.swift_code')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_iban_code'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.iban_code')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_name'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_name')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_address'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_address')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_city'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_city')},
                {type: 'select', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_country'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_country'), items: countriesSelect},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_name_surname'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.name_surname')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_address'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.address')},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_city'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.city')},
                {type: 'select', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_country'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.country'), items: countriesSelect}
            ]
        },
    },
    methods: {
        changeDefault() {
            this.defaultBankTransfer = !this.defaultBankTransfer
            this.$store.state.user.user.data.withdrawDefault = this.defaultBankTransfer ? 'bank' : null
        },
        validateForm(form) {
            for (const key in form) {
                if (form.hasOwnProperty(key)) {
                    const value = form[key]
                    if (value === null || value === undefined || value === '') {
                        return false
                    }
                }
            }
            return true
        },
        save() {
            const isValid = this.validateForm(this.model);
            if (!isValid) {
                this.isShowWithdrawFormErrorDialog = true;
                return
            }

            this.model.default = this.defaultBankTransfer
            this.sendBankTransfer({model: this.model})
                .then((result) => {
                    this.success = result || {}
                    this.errors = {}
                }, (error) => {
                    this.errors = error.response.data.errors || {}
                })
        },
        getFieldName(label) {
            return label.toLowerCase().replaceAll(' ', '_').trim()
        },
        handleCloseWithdrawErrorFormDialog() {
            this.isShowWithdrawFormErrorDialog = false
        },
        ...mapActions([
            'sendBankTransfer',
            'getBankTransfer',
        ]),
    },
    mounted() {
        this.getBankTransfer().then((response) => {
            this.model = response.data || {}
        })
    },
    components: {
        WithdrawalFormErrorDialog,
        AccessCameraDialog,
        BottomControlBlock,
        TextField,
        SettingLayout
    }
}
</script>

<style scoped>

</style>
